exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-help-[id]-js": () => import("./../../../src/pages/Help/[id].js" /* webpackChunkName: "component---src-pages-help-[id]-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/Help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-[id]-js": () => import("./../../../src/pages/Menu/[id].js" /* webpackChunkName: "component---src-pages-menu-[id]-js" */),
  "component---src-pages-news-[id]-js": () => import("./../../../src/pages/News/[id].js" /* webpackChunkName: "component---src-pages-news-[id]-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/News/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/Store.js" /* webpackChunkName: "component---src-pages-store-js" */)
}

