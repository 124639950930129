/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
// In gatsby-browser.js o gatsby-ssr.js per l'uso nell'applicazione Gatsby
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './apollo-confing'; // Importa l'istanza di ApolloClient configurata

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);
